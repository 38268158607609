"use client";
import { useSetCampaign } from "@/lib/hooks/useChangeLocation/useChangeLocation";
import { useSelection } from "@/lib/hooks/useSelection";
import { canStartCampaign } from "@/lib/utils/campaign";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

export const SetCampaign = () => {
  const setCampaign = useSetCampaign();
  const { loggedIn, data, market } = useSelection();
  const isLoggedIn = !!loggedIn; // Must be stable boolean reference

  const token = data?.token || "";
  const searchParams = useSearchParams();
  const vip =
    searchParams.has(
      "vip",
    ); /* should come from storyblok/centra so that it can be changed and invalidated */

  useEffect(() => {
    if (isLoggedIn) {
      if (!canStartCampaign(market)) return;
      setCampaign.mutate({ uri: "owners-club-loggedin" });
    } else if (vip) {
      if (!canStartCampaign(market)) return;
      setCampaign.mutate({ uri: "owners-club" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, market, token, vip]);
  return null;
};
